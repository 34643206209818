import { useUiStore } from '~/store/ui';
export default function useMenuHelper() {

  const uiStore = useUiStore();

  const router = useRouter();
  const clickMenu = (url:string, children:boolean, _uid:string, ignoreParent?:  boolean) => {
    if (children) {
      uiStore.openSideMenuWith({ uid: _uid, ignoreParent });
    } else {
      router.push({ path: `${url}` });
    }
  };
  const clickMenuDesktop = (url:string, children:boolean, _uid:string, ignoreParent?:  boolean) => {
    if (children) {
      uiStore.openDesktopMenuWith({ uid: _uid, ignoreParent });
    } else {
      router.push({ path: `${url}` });
    }
  };

  /**
   * For some reason, pinia has converted my array to proxyobj
   */
  const checkDesign = (obj: any, check: string) => {
    if (typeof obj !== 'object') {
      return false;
    } else {
      return Object.values(obj).includes(check);
    }
  };

  return {
    checkDesign,
    clickMenu,
    clickMenuDesktop,
  };
}
